<template>
    <div class="index">
        <!-- 企业心理服务 -->
        <div class="banner">
            <img v-if="!$store.state.isPhone" :src="$store.state.bannerUrl + $store.state.bannerImgs.qyxlfw" alt="">
      <img v-else src="../../assets/images/banner/企业心理服务banner备份.jpg" alt=""/>
            <!-- <img :src="$store.state.bannerUrl + $store.state.bannerImgs.qyxlfw" alt=""> -->
        </div>
        <div :class="isPc === 'true' ? 'main': 'phoneStyle main'">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isPc: true,
        }
    },
    created() {
        this.isPc = sessionStorage.getItem('isPc')
    }
}
</script>

<style lang="scss" scoped>
.index {
    width: 100%;

    .banner {
        img {
            height: 300px;
            width: 100%;
        }
    }

    .main {
        display: block;

        // .btn {
        //     cursor: pointer;
        //     margin-bottom: 15px;
        //     font-weight: 600;
        //     color: var(--headFont-color);
        //     width: 110px;
        //     height: 32px;
        //     text-align: center;
        //     line-height: 32px;
        //     background: #FFFFFF;
        //     border: 1px solid var(--custom-color);
        // }
    }
}
</style>